import React, { useEffect } from "react";

function OfferComponent({ offers }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div>
      <section className="about">
        <div className="container section-padding">
          <div className="row md-marg align-items-center justify-content-center">
            <div className="col-lg-4 offset-lg-1">
              <div
                className="cont md-mb50"
                style={{ border: "1px solid #ccc", padding: "1.5em" }}
              >
                <h6
                  className="sub-title  mb-15 text-center"
                  style={{ color: "#FFF" }}
                >
                  {offers?.section_1_left_title} <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: offers?.section_1_left_desc,
                    }}
                  ></span>
                </h6>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="cont md-mb50">
                <h6 className="sub-title main-color mb-15 text-center">
                  {offers?.section_1_right_title}
                </h6>
                <div>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{
                      __html: offers?.section_1_right_desc,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OfferComponent;
