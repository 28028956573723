"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import data from "../../../data/testimonials.json";
function Testimonials({ memberDetails }) {
  const swiperOptions = {
    modules: [Navigation],

    spaceBetween: 30,
    speed: 1000,
    loop: true,
    navigation: {
      nextEl: ".swiper-arrow-control .swiper-button-next",
      prevEl: ".swiper-arrow-control .swiper-button-prev",
    },
  };
  return (
    <section className="testimonials">
      <div className="container">
        <div className="sec-head mb-40 mt-40">
          <h6 className="sub-title mb-15 main-color text-center">
            Patients Say About {memberDetails?.member_name}
          </h6>
          <div className="bord bord-thin-top d-flex align-items-center"></div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-3">
            <div className="video-containerOuter">
              <div className="video-container">
                <iframe
                  className="responsive-iframe"
                  src="https://www.youtube.com/embed/sSqW5TVvcuI?rel=0&si=VaJj4l_rA7GePNRV" // Added ?rel=0 to disable unrelated video suggestions
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="col-lg-9 position-re">
            <div className="testim-swiper">
              <Swiper
                id="content-carousel-container-unq-testim"
                className="swiper-container"
                data-swiper="container"
                loop={true}
                {...swiperOptions}
              >
                {data.slice(0, 2).map((item, i) => (
                  <SwiperSlide key={i}>
                    <div className="item">
                      <div className="content">
                        <div className="info d-flex pb-40 mb-40 bord-thin-bottom">
                          {/* <div>
                            <div className="fit-img circle">
                              <img src={item.img} alt="" />
                            </div>
                          </div> */}
                          <div className="ml-20 w-100">
                            <h5 className="text-center">{item.name}</h5>
                            <span className="sub-title main-color d-flex align-items-center justify-content-center">
                              {/* {item.subName} */}
                              {[...Array(5)].map((ele, index) => {
                                return (
                                  <img
                                    style={{
                                      width: "20px",
                                    }}
                                    key={index}
                                    className="px-1"
                                    src={item.star}
                                    alt="start"
                                  />
                                );
                              })}
                            </span>
                          </div>
                        </div>
                        <div className="text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="256.721"
                            height="208.227"
                            viewBox="0 0 256.721 208.227"
                            className="qout-svg"
                          >
                            <path
                              data-name="Path"
                              d="M-23.723-530.169v97.327H-121.05v-68.7q0-40.076,13.359-73.472T-62.845-639.9l36.259,28.625Q-63.8-570.244-68.57-530.169Zm158.395,0v97.327H37.345v-68.7q0-40.076,13.359-73.472T95.55-639.9l36.259,28.625Q94.6-570.244,89.825-530.169Z"
                              transform="translate(121.55 640.568)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="1"
                              opacity="0.322"
                            ></path>
                          </svg>
                          <p className="fz-30 text-center">{item.desc}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="swiper-arrow-control control-abslout">
              <div className="swiper-button-prev">
                <span className="ti-arrow-left"></span>
              </div>
              <div className="swiper-button-next">
                <span className="ti-arrow-right"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="small-line">
        <div className="line-overlay">
          <svg
            viewBox="0 0 1728 1101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-43 773.821C160.86 662.526 451.312 637.01 610.111 733.104C768.91 829.197 932.595 1062.9 602.782 1098.75C272.969 1134.6 676.888 25.4306 1852 1"
              style={{ strokeDasharray: 3246.53 }}
            ></path>
          </svg>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
