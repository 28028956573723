import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, FETCH_OFFERS_DATA } from "../api"; // Update the API endpoint as per your application

// Async thunk to fetch offers data
export const fetchOffersData = createAsyncThunk(
  "offersData/fetchOffersData",
  async () => {
    try {
      const response = await axios.get(`${BASE_URL}${FETCH_OFFERS_DATA}`);
      return response?.data?.data; // Assuming 'data' contains the offers information
    } catch (error) {
      throw Error("Failed to fetch offers data.");
    }
  }
);

// Slice definition for offersData
const offersDataSlice = createSlice({
  name: "offersData",
  initialState: {
    offers: [], // Assuming offers will be an array
    status: "idle",
    error: null,
  },
  reducers: {}, // You can add reducers if needed
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffersData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOffersData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.offers = action.payload; // Store the fetched offers data
      })
      .addCase(fetchOffersData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default offersDataSlice.reducer;
