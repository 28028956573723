import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceNavigationData } from "../../../app/features/serviceNavigationSlice";

function Navbar({ businessInfo, toggleModal }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchServiceNavigationData());
  }, [dispatch]);

  const navigationItems = useSelector(
    (state) => state.serviceNavigation.navigationItems
  );

  const [openIndex, setOpenIndex] = useState(null);

  // Toggle the visibility of a specific submenu
  const toggleSubMenu = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle between open and closed states
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <div className="menu-icon cursor-pointer">
            <span className="icon ti-align-right"></span>
            <span className="px-2" style={{ letterSpacing: "0.1em" }}>
              MENU
            </span>
          </div>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarSupportedContent"
          >
            <Link className="logo icon-img-193" to="/">
              <img src={businessInfo?.main_logo} alt="logo" />
            </Link>
          </div>
          <div className="topnav">
            <button
              onClick={toggleModal}
              className="butn butn-md-custom butn-bord"
              // to="/appointment-book"
            >
              Book an Appointment
            </button>
          </div>
        </div>
      </nav>

      <div className="hamenu">
        <div className="close-menu cursor-pointer ti-close"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="menu-links">
                <ul className="main-menu rest">
                  <li>
                    <div className="o-hidden">
                      <Link to="/" className="link">
                        <span className="fill-text" data-text="Home">
                          Home
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <Link to="/about-us" className="link">
                        {/* <a href="#" className="link"> */}
                        <span className="fill-text" data-text="About Us">
                          About Us
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <div className="link cursor-pointer dmenu">
                        <Link to="/our-services">
                          <span className="fill-text" data-text="Services">
                            Services
                          </span>{" "}
                        </Link>
                        <i></i>
                      </div>
                    </div>
                    <div className="sub-menu no-bord">
                      <ul>
                        {navigationItems?.map((item, index) => {
                          return (
                            <li key={index}>
                              <div className="o-hidden">
                                <div
                                  className="link cursor-pointer sub-dmenu"
                                  onClick={() => toggleSubMenu(index)} // Pass index to identify which submenu to toggle
                                >
                                  <Link
                                    to={`/our-services/services-details/${item?.service_slug}`}
                                  >
                                    <span
                                      className="fill-text"
                                      data-text={item?.service_name}
                                    >
                                      {item?.service_name}
                                    </span>
                                  </Link>
                                  <i></i>
                                </div>
                              </div>

                              {item?.child_services.length > 0 && (
                                <div
                                  className={`sub-menu2 ${
                                    openIndex === index ? "sub-open" : ""
                                  }`} // Apply "sub-open" only for the open submenu
                                  style={{
                                    display:
                                      openIndex === index ? "block" : "none",
                                  }} // Toggle visibility based on the open index
                                >
                                  <ul>
                                    {item.child_services.map((ele, idx) => (
                                      <li key={idx}>
                                        <Link
                                          to={`/our-services/services-details/${ele?.service_slug}`}
                                          className="sub-link"
                                        >
                                          {ele?.service_name}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <Link to="/our-doctors" className="link">
                        <span className="fill-text" data-text="Our Team">
                          Our Team
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <Link to="/our-gallery" className="link">
                        <span className="fill-text" data-text="Gallery">
                          Gallery
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <Link to="/reviews" className="link">
                        <span className="fill-text" data-text="Reviews">
                          Reviews
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <Link to="/offers" className="link">
                        <span className="fill-text" data-text="Offers">
                          Offers
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <Link to="/careers" className="link">
                        <span className="fill-text" data-text="Careers">
                          Careers
                        </span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <Link to="/contact-us" className="link">
                        <span className="fill-text" data-text="Contact Us">
                          Contact Us
                        </span>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
