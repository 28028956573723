import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, FETCH_CAREERS_DATA } from "../api"; // Ensure these constants are defined in your API configuration

// Async thunk to fetch careers data
export const fetchCareersData = createAsyncThunk(
  "careersData/fetchCareersData",
  async () => {
    try {
      const response = await axios.get(`${BASE_URL}${FETCH_CAREERS_DATA}`);
      return response?.data?.data; // Assuming 'data' contains the careers information
    } catch (error) {
      throw Error("Failed to fetch careers data.");
    }
  }
);

// Slice definition for careersData
const careersSlice = createSlice({
  name: "careersData",
  initialState: {
    careers: [], // Assuming careers will be an array
    status: "idle", // Tracks the status: 'idle', 'loading', 'succeeded', or 'failed'
    error: null, // Stores error messages, if any
  },
  reducers: {}, // Add any non-async reducers here if needed
  extraReducers: (builder) => {
    builder
      .addCase(fetchCareersData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCareersData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.careers = action.payload; // Store the fetched careers data
      })
      .addCase(fetchCareersData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default careersSlice.reducer;
