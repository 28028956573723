import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Footer() {
  const businessInfo = useSelector((state) => state.business.businessInfo);
  return (
    <footer
      className="clean-footer crev bg-img"
      data-background="/dark/assets/imgs/header/bg1.jpg"
      data-overlay-dark="10"
    >
      <div className="container pb-40 section-padding ontop">
        <div className="cal-action pb-50 mb-80 bord-thin-bottom">
          <h2 className="fz-120 line-height-1 d-rotate wow">
            <span className="rotate-text">
              {/* <a href="/dark/page-contact3"> */}
              <Link to="/contact-us">
                {businessInfo?.contact_text}
                <span className="ml-30 fz-70 ti-arrow-top-right"></span>
              </Link>
            </span>
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="logo icon-img-193">
              <img
                src={businessInfo?.footer_logo}
                alt={businessInfo?.footer_alt}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="column">
              <div className="mb-40">
                <h6 className="sub-title mb-30">Address</h6>
                <h5 className="opacity-8">{businessInfo?.address_1}</h5>
              </div>

              <a href="#0" className="underline">
                <span className="fz-22 main-color">
                  {businessInfo?.office_phone}
                </span>
              </a>
            </div>
          </div>
          <div className="col-lg-2 offset-lg-1">
            <div className="column">
              <h6 className="sub-title mb-30">Useful Links</h6>
              <ul className="rest fz-14">
                <li className="mb-15">
                  {/* <a href="/dark/page-about">About</a> */}
                  <Link to="/about-us">About</Link>
                </li>
                <li className="mb-15">
                  {/* <a href="/dark/page-services">Services</a> */}
                  <Link to="/our-services">Services</Link>
                </li>
                <li>
                  {/* <a href="/dark/page-contact">Contact</a> */}
                  <Link to="/contact-us">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="column">
              <h6 className="sub-title mb-30">Support</h6>
              <ul className="rest fz-14">
                <li className="mb-15">
                  {/* <a href="/dark/page-about">Term & Conditions</a> */}
                  <Link to="/terms-and-condition">Term & Conditions</Link>
                </li>
                <li className="mb-15">
                  <Link to="/privecy-policy">Privacy policy</Link>
                  {/* <a href="/dark/page-about">Privacy policy</a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center pt-30 pb-30 mt-80 bord-thin-top">
          <div>
            <ul className="rest d-flex align-items-center">
              {businessInfo?.facebook_link && (
                <li className="hover-this cursor-pointer">
                  <a href={businessInfo?.facebook_link} className="hover-anim">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
              )}
              {businessInfo?.youtube_link && (
                <li className="hover-this cursor-pointer ml-30">
                  <a href={businessInfo?.youtube_link} className="hover-anim">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              )}
              {businessInfo?.linkedin_link && (
                <li className="hover-this cursor-pointer ml-30">
                  <a href={businessInfo?.linkedin_link} className="hover-anim">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              )}
              {businessInfo?.insta_link && (
                <li className="hover-this cursor-pointer ml-30">
                  <a href={businessInfo?.insta_link} className="hover-anim">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              )}
              {businessInfo?.twitter_link && (
                <li className="hover-this cursor-pointer ml-30">
                  <a href={businessInfo?.twitter_link} className="hover-anim">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="ml-auto">
            <p className="fz-14">
              ©2024 Solanki Dental Clinic All rights reserved. Powered by{" "}
              <span className="underline main-color">
                <a href="https://asharinfotech.com/" target="_blank">
                  Asharinfotech
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
