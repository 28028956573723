import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Services2 from "../../components/dark/digital-agency/Services";
import { useEffect } from "react";
import Header from "../../components/dark/about2/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceNavigationData } from "../../app/features/serviceNavigationSlice";
import DynamicHeader from "../../components/dark/DynamicHeader";
import { Link } from "react-router-dom";

export default function Services() {
  const dispatch = useDispatch();
  const navigationItems = useSelector(
    (state) => state.serviceNavigation.navigationItems
  );
  useEffect(() => {
    dispatch(fetchServiceNavigationData());
  }, [dispatch]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>Our services</title>
      </Helmet>
      <body>
        {/* <Intro title="About Us" /> */}
        <DynamicHeader
          title="Our Services"
          beforePage="Home"
          currentPage="Our Services"
        />
        <section className="services pb-50 mt-50">
          <div className="container">
            <div className="sec-head mb-40">
              <h6 className="sub-title main-color mb-25">Our All Services</h6>
              <div className="bord pt-25 bord-thin-top d-flex align-items-center">
                <div className="ml-auto">
                  {/* <a href="/dark/page-services2" className="go-more">
                          <span className="text">View all services</span>
                          <span className="icon ti-arrow-top-right"></span>
                        </a> */}
                </div>
              </div>
            </div>
            <div className="row">
              {navigationItems?.map((item, i) => (
                <div key={i} className="col-lg-4 mb-30">
                  <div className="item-box radius-15 md-mb50">
                    <div className="icon mb-40 opacity-5">
                      <img src={item?.service_icon} alt={item?.service_slug} />
                    </div>
                    <h5 className="mb-15">{item?.service_name}</h5>
                    <p>{item?.short_desc}</p>
                    <Link
                      to={`/our-services/services-details/${item?.service_slug}`}
                      className="rmore mt-30"
                    >
                      <span className="sub-title">Read More</span>
                      <img
                        src="/dark/assets/imgs/arrow-right.png"
                        alt=""
                        className="icon-img-20 ml-5"
                      />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </body>
    </>
  );
}
