export const BASE_URL = "https://solankiapi.hybridtech.in/api";
export const IMAGE_BASE_URL = "https://solankiapi.hybridtech.in/";
export const REVIEWS_API =
  "https://places.googleapis.com/v1/places/ChIJrdL8gQ5ZXz4RoAgTDEq-P2Y?fields=id,displayName,reviews&key=AIzaSyB9d27g8X1hGlM7CrCOLjRL6J_BFL2_QZc";
export const FETCH_BUSINESS = "/business-data";
export const FETCH_HOME_BANNER = "/home-banners";
export const FETCH_WHY_CHOOSE_US = "/why_choose";
export const FETCH_SERVICE_NAVIGATION = "/navigation";
export const FETCH_MEMBER_DATA = "/teams";
export const SERVICE_DETAILS_API = "/navigation";
export const FETCH_ABOUT_US_DATA = "/aboutus";
export const FETCH_GALLERY_DATA = "/all-gallery";
export const APPOINTMENT_API = "/add-appointment";
export const CONTACT_API = "/contact-us";
export const FETCH_POLICY_DATA = "/policies";
export const FETCH_OFFERS_DATA = "/offer-data";
export const FETCH_CAREERS_DATA = "/career-data";
