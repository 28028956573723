import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Header from "../../components/dark/portfolio-gallery/Header";
import Portfolio from "../../components/dark/portfolio-gallery/Portfolio";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGalleryData } from "../../app/features/galleryDataSlice";
export default function PortfolioGallery() {
  const dispatch = useDispatch();
  const [galleryData, setGalleryData] = useState([]);
  useEffect(() => {
    dispatch(fetchGalleryData());
  }, [dispatch]);

  const images = useSelector((state) => state.galleryData.images);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const transformData = (data) => {
    return data.flatMap((item) =>
      item.images.map((image) => ({
        id: item.id,
        category: item.category,
        before: image.before,
        after: image.after,
      }))
    );
  };

  useEffect(() => {
    const flattenedData = transformData(images);
    setGalleryData(flattenedData);
  }, [images]);

  return (
    <>
      <Helmet>
        <title>Our Gallery</title>
      </Helmet>
      <body>
        <Header />
        <Portfolio portfolioData={galleryData} />
        <Footer />
      </body>
    </>
  );
}
