import { Helmet } from "react-helmet";
import Footer from "../../components/dark/modern-agency/Footer";
import Lines from "../../components/dark/common/Lines";
import ProgressScroll from "../../components/dark/common/ProgressScroll";
import Cursor from "../../components/dark/common/cusor";
import LoadingScreen from "../../components/dark/common/loader";
import Navbar from "../../components/dark/creative-agency/Navbar";
import Error from "../../components/dark/error404/Error";
export default function PageError404() {
  return (
    <>
      <Helmet>
        <title>Error</title>
      </Helmet>
      <body>
        <LoadingScreen />
        <Cursor />
        <ProgressScroll />
        <Lines />
        <div id="smooth-wrapper">
          <Navbar />
          <div id="smooth-content">
            <main className="main-bg">
              <Error />
            </main>
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
}
