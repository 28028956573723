import { Helmet } from "react-helmet";
import Footer from "../home-main/Footer";
import Header from "./Header";
import { useEffect } from "react";
import { fetchReviewsData } from "../../../app/features/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";
import "./reviewsStyle.css";
export default function Reviews() {
  const dispatch = useDispatch();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  //   Reviews api

  const reviewsStatus = useSelector((state) => state.reviews.status);
  const reviewsData = useSelector((state) => state.reviews.items);
  useEffect(() => {
    if (reviewsStatus === "idle") {
      dispatch(fetchReviewsData());
    }
  }, [reviewsStatus, dispatch]);

  console.log("reviewsData: ", reviewsData);

  return (
    <>
      <Helmet>
        <title>Dr Reviews</title>
      </Helmet>
      <body>
        <Header />
        <div className="container mt-50">
          <div className="review-bg-color">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 85 36"
                    className="google-logo"
                  >
                    <g clipPath="url(#a-1)">
                      <path
                        fill="#4285F4"
                        d="M20.778 13.43h-9.862v2.927h6.994c-.345 4.104-3.76 5.854-6.982 5.854-4.123 0-7.72-3.244-7.72-7.791 0-4.43 3.429-7.841 7.73-7.841 3.317 0 5.272 2.115 5.272 2.115l2.049-2.122s-2.63-2.928-7.427-2.928C4.725 3.644 0 8.8 0 14.367c0 5.457 4.445 10.777 10.988 10.777 5.756 0 9.969-3.942 9.969-9.772 0-1.23-.179-1.941-.179-1.941Z"
                      />
                      <path
                        fill="#EA4335"
                        d="M28.857 11.312c-4.047 0-6.947 3.163-6.947 6.853 0 3.744 2.813 6.966 6.994 6.966 3.786 0 6.887-2.893 6.887-6.886 0-4.576-3.607-6.933-6.934-6.933Zm.04 2.714c1.99 0 3.876 1.609 3.876 4.201 0 2.538-1.878 4.192-3.885 4.192-2.205 0-3.945-1.766-3.945-4.212 0-2.394 1.718-4.181 3.954-4.181Z"
                      />
                      <path
                        fill="#FBBC05"
                        d="M43.965 11.312c-4.046 0-6.946 3.163-6.946 6.853 0 3.744 2.813 6.966 6.994 6.966 3.785 0 6.886-2.893 6.886-6.886 0-4.576-3.607-6.933-6.934-6.933Zm.04 2.714c1.99 0 3.876 1.609 3.876 4.201 0 2.538-1.877 4.192-3.885 4.192-2.205 0-3.945-1.766-3.945-4.212 0-2.394 1.718-4.181 3.955-4.181Z"
                      />
                      <path
                        fill="#4285F4"
                        d="M58.783 11.319c-3.714 0-6.634 3.253-6.634 6.904 0 4.16 3.385 6.918 6.57 6.918 1.97 0 3.017-.782 3.79-1.68v1.363c0 2.384-1.448 3.812-3.633 3.812-2.11 0-3.169-1.57-3.537-2.46l-2.656 1.11c.943 1.992 2.839 4.07 6.215 4.07 3.693 0 6.508-2.327 6.508-7.205V11.734h-2.897v1.17c-.89-.96-2.109-1.585-3.726-1.585Zm.269 2.709c1.821 0 3.69 1.554 3.69 4.21 0 2.699-1.865 4.187-3.73 4.187-1.98 0-3.823-1.608-3.823-4.161 0-2.653 1.914-4.236 3.863-4.236Z"
                      />
                      <path
                        fill="#EA4335"
                        d="M78.288 11.302c-3.504 0-6.446 2.788-6.446 6.901 0 4.353 3.28 6.934 6.782 6.934 2.924 0 4.718-1.6 5.789-3.032l-2.389-1.59c-.62.962-1.656 1.902-3.385 1.902-1.943 0-2.836-1.063-3.39-2.094l9.266-3.845-.48-1.126c-.896-2.207-2.984-4.05-5.747-4.05Zm.12 2.658c1.263 0 2.171.671 2.557 1.476l-6.187 2.586c-.267-2.002 1.63-4.062 3.63-4.062Z"
                      />
                      <path
                        fill="#34A853"
                        d="M67.425 24.727h3.044V4.359h-3.044v20.368Z"
                      />
                    </g>
                    <defs>
                      <clipPath id="a-1">
                        <path fill="#fff" d="M0 0h84.515v36H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h5 className="text-dark mx-2">Reviews</h5>
                </div>
                <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                  <div>
                    <h5 className="text-dark">4.8</h5>
                  </div>
                  <div className="mx-2">
                    <ReactStars
                      count={5}
                      edit={false}
                      //   onChange={ratingChanged}
                      value={5}
                      size={24}
                      activeColor="#ffd700"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-center justify-content-md-end pt-2">
                <button
                  className="ButtonBase__ButtonContainer-sc-p43e7i-3 euBiGU HeaderWriteReviewButton__WriteReviewButton-sc-a5mrro-1 iqYjDs es-header-write-review-button"
                  type="button"
                  tabIndex="0"
                  style={{
                    borderRadius: "4px",
                    borderColor: "rgba(0, 0, 0, 0)",
                    lineHeight: "1.32",
                    color: "rgb(255, 255, 255)",
                    fontFamily: "inherit",
                    fontWeight: "bold",
                    fontSize: "14px",
                    fontStyle: "normal",
                    backgroundColor: "rgb(25, 123, 255)",
                    borderWidth: "2px",
                  }}
                >
                  <a
                    target="_blank"
                    href="https://www.google.com/search?hl=en-IN&gl=in&q=9C3J%2BX6M+Solanki+Dental+Center+-+Villa+No3+-+Street+2+-+Al+Falaj+-+Al+Riqa+Suburb+-+Sharjah+-+United+Arab+Emirates&ludocid=7367816740640852128&lsig=AB86z5VuRN5NSv0klidMtJM647eX#lrd=0x3e5f590e81fcd2ad:0x663fbe4a0c1308a0,3"
                    className="ButtonBase__Overlay-sc-p43e7i-4 cjTloD"
                    style={{
                      padding: "8px 20px",
                      borderRadius: "calc(2px)",
                      backgroundColor: "rgba(0, 0, 0, 0)",
                    }}
                  >
                    <span className="ButtonBase__Ellipsis-sc-p43e7i-5 dqiKFy">
                      Review us on Google
                    </span>
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="customer-review-container">
            <div className="row">
              {reviewsData?.map((ele, index) => {
                return (
                  <div
                    className="col-12 col-md-6 col-lg-4 col-xl-3"
                    key={index}
                  >
                    <div className="review-card-view d-flex flex-column align-items-center justify-content-center px-4 py-3">
                      <div className="mx-2">
                        <ReactStars
                          count={5}
                          edit={false}
                          //   onChange={ratingChanged}
                          value={ele?.rating}
                          size={24}
                          activeColor="#ffd700"
                        />
                      </div>
                      <div className="my-2 d-flex  d-flex flex-column align-items-center justify-content-center">
                        <p className="textFixedLine3 text-center text-dark">
                          {ele?.originalText?.text}
                        </p>
                        <a
                          target="_blank"
                          href={ele?.authorAttribution?.uri}
                          className="review-read-more-btn fw-600 text-center"
                        >
                          Read More
                        </a>
                      </div>

                      <div>
                        <img
                          className="user-img"
                          src={ele?.authorAttribution?.photoUri}
                          alt="user"
                        />
                      </div>
                      <div className="my-2">
                        <h5 className="fz-18 text-dark mx-2">
                          {ele?.authorAttribution?.displayName}
                        </h5>
                      </div>
                      <div>
                        <span className="fw-10 text-dark">
                          {ele?.relativePublishTimeDescription}
                        </span>
                      </div>
                      <div className="mt-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 85 36"
                          className="google-logo-two"
                        >
                          <g clipPath="url(#a-1)">
                            <path
                              fill="#4285F4"
                              d="M20.778 13.43h-9.862v2.927h6.994c-.345 4.104-3.76 5.854-6.982 5.854-4.123 0-7.72-3.244-7.72-7.791 0-4.43 3.429-7.841 7.73-7.841 3.317 0 5.272 2.115 5.272 2.115l2.049-2.122s-2.63-2.928-7.427-2.928C4.725 3.644 0 8.8 0 14.367c0 5.457 4.445 10.777 10.988 10.777 5.756 0 9.969-3.942 9.969-9.772 0-1.23-.179-1.941-.179-1.941Z"
                            />
                            <path
                              fill="#EA4335"
                              d="M28.857 11.312c-4.047 0-6.947 3.163-6.947 6.853 0 3.744 2.813 6.966 6.994 6.966 3.786 0 6.887-2.893 6.887-6.886 0-4.576-3.607-6.933-6.934-6.933Zm.04 2.714c1.99 0 3.876 1.609 3.876 4.201 0 2.538-1.878 4.192-3.885 4.192-2.205 0-3.945-1.766-3.945-4.212 0-2.394 1.718-4.181 3.954-4.181Z"
                            />
                            <path
                              fill="#FBBC05"
                              d="M43.965 11.312c-4.046 0-6.946 3.163-6.946 6.853 0 3.744 2.813 6.966 6.994 6.966 3.785 0 6.886-2.893 6.886-6.886 0-4.576-3.607-6.933-6.934-6.933Zm.04 2.714c1.99 0 3.876 1.609 3.876 4.201 0 2.538-1.877 4.192-3.885 4.192-2.205 0-3.945-1.766-3.945-4.212 0-2.394 1.718-4.181 3.955-4.181Z"
                            />
                            <path
                              fill="#4285F4"
                              d="M58.783 11.319c-3.714 0-6.634 3.253-6.634 6.904 0 4.16 3.385 6.918 6.57 6.918 1.97 0 3.017-.782 3.79-1.68v1.363c0 2.384-1.448 3.812-3.633 3.812-2.11 0-3.169-1.57-3.537-2.46l-2.656 1.11c.943 1.992 2.839 4.07 6.215 4.07 3.693 0 6.508-2.327 6.508-7.205V11.734h-2.897v1.17c-.89-.96-2.109-1.585-3.726-1.585Zm.269 2.709c1.821 0 3.69 1.554 3.69 4.21 0 2.699-1.865 4.187-3.73 4.187-1.98 0-3.823-1.608-3.823-4.161 0-2.653 1.914-4.236 3.863-4.236Z"
                            />
                            <path
                              fill="#EA4335"
                              d="M78.288 11.302c-3.504 0-6.446 2.788-6.446 6.901 0 4.353 3.28 6.934 6.782 6.934 2.924 0 4.718-1.6 5.789-3.032l-2.389-1.59c-.62.962-1.656 1.902-3.385 1.902-1.943 0-2.836-1.063-3.39-2.094l9.266-3.845-.48-1.126c-.896-2.207-2.984-4.05-5.747-4.05Zm.12 2.658c1.263 0 2.171.671 2.557 1.476l-6.187 2.586c-.267-2.002 1.63-4.062 3.63-4.062Z"
                            />
                            <path
                              fill="#34A853"
                              d="M67.425 24.727h3.044V4.359h-3.044v20.368Z"
                            />
                          </g>
                          <defs>
                            <clipPath id="a-1">
                              <path fill="#fff" d="M0 0h84.515v36H0z" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Footer />
      </body>
    </>
  );
}
