import { Helmet } from "react-helmet";
import React, { useState } from "react";
import Footer from "../../components/dark/home-main/Footer";
import Portfolio from "../../components/dark/creative-agency/Portfolio";
import Services from "../../components/dark/modern-agency/Services";
import Services2 from "../../components/dark/digital-agency/Services";
import VideoSliderHeader from "../../components/dark/modern-agency/VideoSliderHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchWhyChooseUsData } from "../../app/features/whyChooseUsSlice";
import { useEffect } from "react";
import OfferHeader from "../../components/dark/services2/OfferHeader";
import OfferComponent from "../../components/dark/services/OfferComponent";
import { fetchOffersData } from "../../app/features/offersDataSlice";

export default function OfferPage({ toggleModal }) {
  const dispatch = useDispatch();
  const [bannerLoaded, setBannerLoaded] = useState(false);
  const navigationItems = useSelector(
    (state) => state.serviceNavigation.navigationItems
  );

  const { offers, status, error } = useSelector((state) => state.offers);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchOffersData());
    }
  }, [status, dispatch]);
  useEffect(() => {
    dispatch(fetchWhyChooseUsData());
  }, [dispatch]);

  if (status === "loading") return <p></p>;
  if (status === "failed") return <p></p>;

  return (
    <>
      <Helmet>
        <title>
          Solanki Dental Clinic in Sharjah, Dental Clinics in UAE, Best Dentist
          in UAE, Dentist in Sharjah, Dental Centre in sharjah, Solanki dental
          centre{" "}
        </title>
        <meta
          name="description"
          content="Sharjah Dentist | Orthodontics, Pediatric Dentistry & More: Solanki Dental Clinic is your one-stop shop for all your dental needs in Sharjah. We offer expert care using advanced technology. Call us today!"
        />
        <meta
          name="keywords"
          content="Sharjah Dentist | Orthodontics, Pediatric Dentistry & More: Solanki Dental Clinic is your one-stop shop for all your dental needs in Sharjah. We offer expert care using advanced technology. Call us today!"
        />
      </Helmet>

      <OfferHeader offers={offers} />
      <div
        style={{
          backgroundColor: "#14CF93",
        }}
      >
        <div className="container" style={{ padding: "1.8em 0px" }}>
          <div className="row align-items-center">
            <div className="col-12">
              <h6 className="sub-title mb-15 text-center">
                {offers?.banner_title}
              </h6>
            </div>
            <div className="col-12">
              <p
                className="text-center"
                dangerouslySetInnerHTML={{ __html: offers?.banner_desc }}
              ></p>
            </div>
          </div>
        </div>
      </div>
      <OfferComponent offers={offers} />
      {/* <Services2 navigationItems={navigationItems} /> */}
      <Services navigationItems={navigationItems} />
      <div>
        <section className="about">
          <div className="container section-padding">
            <div className="row md-marg">
              <div className="col-lg-4 offset-lg-1">
                <div
                  className="cont md-mb50"
                  style={{ border: "1px solid #ccc", padding: "1.5em" }}
                >
                  <img
                    src={offers?.section_2_left_image}
                    alt={offers?.section_2_left_image_alt}
                  />
                  <h6
                    className="sub-title  mb-15 mt-15 text-center"
                    style={{ color: "#FFF" }}
                  >
                    {offers?.section_2_left_title} <br />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: offers?.section_2_left_desc,
                      }}
                    ></span>
                  </h6>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="cont md-mb50">
                  <h6 className="sub-title main-color mb-15">
                    {offers?.section_2_right_title}
                  </h6>
                  <div>
                    <p
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: offers?.section_2_right_desc,
                      }}
                    ></p>
                  </div>
                </div>
                <button
                  onClick={toggleModal}
                  className="butn butn-md-custom butn-bord mt-25"
                  // to="/appointment-book"
                >
                  Book an Appointment
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />

      {/* <Testimonials /> */}
    </>
  );
}
