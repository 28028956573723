import React from "react";
import data from "../../../data/services.json";
import { Link } from "react-router-dom";

function Services({ navigationItems }) {
  return (
    <section className="services section-padding pb-0">
      <div className="container">
        <div className="sec-head mb-80">
          <h6 className="sub-title main-color mb-25">Our Specialize</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            {/* <h2 className="fw-600 text-u ls1">
              What We <span className="fw-200">Offer</span>
            </h2> */}
            <div className="ml-auto">
              <Link to="/our-services" className="go-more">
                <span className="text">View all services</span>
                <span className="icon ti-arrow-top-right"></span>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          {navigationItems.map((item, i) => (
            <div key={i} className="col-md-6">
              <div className="item-box2 mb-30">
                {/* <div className="icon mb-40 text-center d-flex align-items-center justify-content-center">
                  <img
                    className="text-center"
                    src={item?.service_icon}
                    alt={item?.service_name}
                  />
                </div> */}
                <h5 className="mb-15 text-center">{item?.service_name}</h5>
                <p className="noofTextlinethree text-center">
                  {item?.short_desc}
                </p>
                <Link
                  to={`/our-services/services-details/${item?.service_slug}`}
                  className="rmore"
                >
                  <div className="arrow">
                    <img
                      src="/dark/assets/imgs/arrow-right.png"
                      alt=""
                      className="icon-img-20"
                    />
                  </div>
                  <div className="shap-left-top">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#1a1a1a"
                      ></path>
                    </svg>
                  </div>
                  <div className="shap-right-bottom">
                    <svg
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-11"
                    >
                      <path
                        d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                        fill="#1a1a1a"
                      ></path>
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
