// HelmetComponent.js
import React from "react";
import { Helmet } from "react-helmet";

const DynamicHelmate = ({ metacontent }) => (
  <Helmet>
    <title>{metacontent?.meta_title}</title>
    <meta name="keywords" content={metacontent?.meta_keyword} />
    <meta name="description" content={metacontent?.meta_desc} />
  </Helmet>
);

export default DynamicHelmate;
