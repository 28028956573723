import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Contact from "../../components/dark/contact/Contact";
import Header from "../../components/dark/contact/Header";
import { useEffect } from "react";
import CareersComponent from "../../components/dark/contact/CareersComponent";
import CareersHeader from "../../components/dark/contact/CareersHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchCareersData } from "../../app/features/careersSlice";
export default function Careers({ businessInfo }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const dispatch = useDispatch();
  const { careers, status, error } = useSelector((state) => state.careers);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchCareersData());
    }
  }, [status, dispatch]);

  if (status === "loading") return <p>Loading careers...</p>;
  if (status === "failed") return <p>Error: {error}</p>;

  console.log("careers: ", careers);

  return (
    <>
      <Helmet>
        <title>Careers</title>
      </Helmet>
      <body>
        <CareersHeader />
        <CareersComponent businessInfo={businessInfo} careers={careers} />
        <Footer />
      </body>
    </>
  );
}
