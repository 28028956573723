import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Team from "../../components/dark/team-single/Team";
import { useEffect, useState } from "react";
import TeamProfileGallerySlider from "../../components/dark/portfolio-gallery/TeamProfileGallerySlider";
import { BASE_URL, FETCH_MEMBER_DATA } from "../../app/api";
import { useParams } from "react-router-dom";
import Testimonials from "../../components/dark/home-main/Testimonials";
export default function PageTeamSingle() {
  const { id } = useParams();
  const [memberDetails, setMemberDetails] = useState(null);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Replace this URL with your actual API endpoint or data fetching logic
    fetch(`${BASE_URL}${FETCH_MEMBER_DATA}/${id}`)
      .then((response) => response.json())
      .then((data) => setMemberDetails(data?.data))
      .catch((error) => console.error("Error fetching memberDetails:", error));
  }, [id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  // console.log("memberDetails: ", memberDetails);

  return (
    <>
      <Helmet>
        <title> {`Our Doctor || ${memberDetails?.member_name}`}</title>
      </Helmet>
      <body>
        <Team memberDetails={memberDetails} />
        <TeamProfileGallerySlider
          memberDetailsgallery={memberDetails?.gallery}
        />
        <Testimonials memberDetails={memberDetails} />
        <section className="testimonials">
          <div className="container">
            <div className="sec-head mb-40 mt-40">
              <h6 className="sub-title mb-15 main-color text-center">
                Up, Close & Personal
              </h6>
              <div className="bord-thin-top d-flex align-items-center"></div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<p style="text-align: start;color: rgb(0, 0, 0);font-size: 16px;font-family: Poppins, sans-serif;">Dentistry is not just about teeth and Dr. Marwan Al-Obeidi couldn&rsquo;t agree more. For him, health promotion plays a rather big role in our everyday life. &ldquo;I feel that by improving the oral health of my patients and by guiding them to better oral care at home I am ensuring that my patients are always healthy and happy!&rdquo;</p>
<p style="text-align: start;color: rgb(0, 0, 0);font-size: 16px;font-family: Poppins, sans-serif;">Dr. Marwan moved to Dubai earlier to take his career a step further, explore new horizons and bask in the sunshine.</p>
<p style="text-align: start;color: rgb(0, 0, 0);font-size: 16px;font-family: Poppins, sans-serif;">&ldquo;Dubai and London are great in their own way&rdquo;, he said. &ldquo;Dubai is warm, so are the people, has dazzling architecture and filled with activities, London is steeped with history and culture, filled with beautiful scenery and buildings, and compared to Dubai very green! The capital of the world!</p>`,
                  }}
                />
              </div>
              <div className="col-lg-6 ">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<p style="text-align: start;color: rgb(0, 0, 0);font-size: 16px;font-family: Poppins, sans-serif;">Dentistry is not just about teeth and Dr. Marwan Al-Obeidi couldn&rsquo;t agree more. For him, health promotion plays a rather big role in our everyday life. &ldquo;I feel that by improving the oral health of my patients and by guiding them to better oral care at home I am ensuring that my patients are always healthy and happy!&rdquo;</p>
<p style="text-align: start;color: rgb(0, 0, 0);font-size: 16px;font-family: Poppins, sans-serif;">Dr. Marwan moved to Dubai earlier to take his career a step further, explore new horizons and bask in the sunshine.</p>
<p style="text-align: start;color: rgb(0, 0, 0);font-size: 16px;font-family: Poppins, sans-serif;">&ldquo;Dubai and London are great in their own way&rdquo;, he said. &ldquo;Dubai is warm, so are the people, has dazzling architecture and filled with activities, London is steeped with history and culture, filled with beautiful scenery and buildings, and compared to Dubai very green! The capital of the world!</p>`,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </body>
    </>
  );
}
