import React from "react";
import { IMAGE_BASE_URL } from "../../../app/api";
import "./team.css";
function Team({ memberDetails }) {
  return (
    <div>
      <section className="about mt-50">
        <div className="container section-padding">
          <div className="row md-marg">
            <div className="col-lg-4">
              <div className="img-vid">
                <img
                  src={IMAGE_BASE_URL + memberDetails?.banner_image}
                  lt={memberDetails?.member_name}
                />
              </div>
              <div className="video-containerOuter">
                <div className="video-container">
                  <iframe
                    className="responsive-iframe"
                    src="https://www.youtube.com/embed/sSqW5TVvcuI?rel=0&si=VaJj4l_rA7GePNRV" // Added ?rel=0 to disable unrelated video suggestions
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="cont md-mb50">
                <h6 className="sub-title main-color mb-15">
                  {memberDetails?.member_name}
                </h6>
                <h6 className="mb-15" style={{ fontSize: "1.5rem" }}>
                  {memberDetails?.designation}
                </h6>
                <div
                  className="elementor-element elementor-element-3e1d9ee0 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                  data-id="3e1d9ee0"
                  data-element_type="widget"
                  data-widget_type="icon-list.default"
                >
                  <div className="elementor-widget-container">
                    <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <i
                            aria-hidden="true"
                            className="fas fa-check-circle"
                          ></i>
                        </span>
                        <span className="elementor-icon-list-text">
                          <strong>Specializations</strong>: General Dentistry,
                          Aesthetic &amp; Restorative Dentistry, Veneers, Dental
                          Crowns and Bridges, Pinhole Gum Rejuvenation,
                          Minimally Invasive&nbsp;Dentistry
                        </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <i
                            aria-hidden="true"
                            className="fas fa-check-circle"
                          ></i>
                        </span>
                        <span className="elementor-icon-list-text">
                          <strong>Experience</strong>: 15+ years of professional
                          experience
                        </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <i
                            aria-hidden="true"
                            className="fas fa-check-circle"
                          ></i>
                        </span>
                        <span className="elementor-icon-list-text">
                          <strong>Languages Spoken</strong>: German,
                          English,&nbsp; Dutch and Farsi
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: memberDetails?.short_note,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Team;
