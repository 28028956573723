import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Contact from "../../components/dark/contact/Contact";
import Header from "../../components/dark/contact/Header";
import { useEffect } from "react";
export default function PageContact({ businessInfo }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <body>
        <Header />
        <Contact businessInfo={businessInfo} />
        <Footer />
      </body>
    </>
  );
}
