import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PortfolioMetro from "./pages/dark/portfolio-metro";
import BlogClassic from "./pages/dark/blog-classic";
import BlogDetails from "./pages/dark/blog-details";
import BlogList from "./pages/dark/blog-list";
import BlogList2 from "./pages/dark/blog-list2";
import HomeMain from "./pages/dark/home-main";
import PageAbout2 from "./pages/dark/page-about2";
import PageContact from "./pages/dark/page-contact";
import PageError404 from "./pages/dark/page-error404";
import PageFAQS from "./pages/dark/page-FAQS";
import PageServices from "./pages/dark/page-services";
import PageServicesDetails from "./pages/dark/page-services-details";
import PageServices2 from "./pages/dark/page-services2";
import PageTeam from "./pages/dark/page-team";
import PageTeamSingle from "./pages/dark/page-team-single";
import PortfolioCaptionCursor from "./pages/dark/portfolio-caption-cursor";
import PortfolioGallery from "./pages/dark/portfolio-gallery";
import PortfolioParallax from "./pages/dark/portfolio-parallax";
import PortfolioSticky from "./pages/dark/portfolio-sticky";
import Project1 from "./pages/dark/project1";
import Project2 from "./pages/dark/project2";
import Project3 from "./pages/dark/project3";
import Project4 from "./pages/dark/project4";
import Project5 from "./pages/dark/project5";
import Project6 from "./pages/dark/project6";
import ShowcaseCarousel from "./pages/dark/showcase-carousel";
import ShowcaseFullScreen from "./pages/dark/showcase-fullscreen";
import ShowcaseHalfSlider from "./pages/dark/showcase-half-slider";
import ShowcaseInteractiveCenter from "./pages/dark/showcase-interactive-center";
import ShowcaseInteractiveFull from "./pages/dark/showcase-interactive-full";
import ShowcaseInteractiveVertical from "./pages/dark/showcase-interactive-vertical";
import CallButtonComp from "./components/dark/common/CallButtonComp";
import LoadingScreen from "./components/dark/common/loader";
import AppointmentComp from "./components/dark/common/AppointmentComp";
import WhatsappComp from "./components/dark/common/WhatsappComp";
import Cursor from "./components/dark/common/cusor";
import ProgressScroll from "./components/dark/common/ProgressScroll";
import Lines from "./components/dark/common/Lines";
import Navbar from "./components/dark/home-main/Navbar";
import Reviews from "./components/dark/reviews";
import TermsCondition from "./pages/dark/page-terms-policy";
import PrivecyPolicy from "./pages/dark/privecy-policy";
import { Helmet } from "react-helmet";
import TawkTo from "./TawkTo";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchBusinessData } from "./app/features/businessSlice";
import { fetchHomeBannerData } from "./app/features/homeBannerSlice";
import Services from "./pages/dark/Services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppointmentBook from "./pages/dark/AppointmentBook";
import AppointmentModal from "./components/dark/common/AppointmentModal";
import Careers from "./pages/dark/Careers";
import OfferPage from "./pages/dark/OfferPage";
function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // API Call
  const businessInfo = useSelector((state) => state.business.businessInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBusinessData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchHomeBannerData());
  }, [dispatch]);

  const toggleModal = () => {
    console.log("its working");

    setIsModalOpen((prev) => !prev);
  };

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <Router>
      <Helmet>
        <title>{businessInfo?.meta_title}</title>
        <meta name="description" content={businessInfo?.meta_desc} />
        <meta name="keywords" content={businessInfo?.meta_keyword} />
        <link rel="icon" href="/dark/imgs/favicon.ico" />
        <link rel="shortcut icon" href="/dark/imgs/favicon.ico" />

        {/* Preload critical CSS */}
        <link rel="preload" href="/dark/assets/css/plugins.css" as="style" />
        <link rel="preload" href="/dark/assets/css/style.css" as="style" />
        <link rel="preload" href="/dark/assets/css/satoshi.css" as="style" />

        {/* Link to external fonts with font-display swap */}
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght..200;300;400;500;600;700&display=swap"
        />

        {/* Inline critical CSS to prevent FOUC */}
        <style>
          {`
      body, html {
        background-color: #1a1a1a;
        margin: 0;
        padding: 0;
        height: 100%;
      }
      /* Add other critical styles if needed here */
    `}
        </style>

        {/* Scripts with defer attribute to load after page rendering */}
        <script src="/dark/assets/js/scripts.js" defer></script>
        <script src="/dark/assets/js/smoother-script.js" defer></script>

        {/* Link to stylesheets */}
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/plugins.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/style.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/dark/assets/css/satoshi.css"
        />
      </Helmet>
      <ToastContainer />
      {/* <LoadingScreen /> */}
      <CallButtonComp />
      <AppointmentComp toggleModal={toggleModal} />
      <WhatsappComp />
      <Cursor />
      {/* <ProgressScroll /> */}
      <Lines />
      <Navbar businessInfo={businessInfo} toggleModal={toggleModal} />
      <AppointmentModal isOpen={isModalOpen} onClose={closeModal} />
      <TawkTo />
      <Routes>
        <Route path="/" element={<HomeMain />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/terms-and-condition" element={<TermsCondition />} />
        <Route path="/privecy-policy" element={<PrivecyPolicy />} />
        <Route
          path="/contact-us"
          element={<PageContact businessInfo={businessInfo} />}
        />
        <Route
          path="/careers"
          element={<Careers businessInfo={businessInfo} />}
        />
        <Route
          path="/offers"
          element={
            <OfferPage businessInfo={businessInfo} toggleModal={toggleModal} />
          }
        />
        <Route
          path="/appointment-book"
          element={<AppointmentBook businessInfo={businessInfo} />}
        />
        <Route path="/our-services" element={<Services />} />

        <Route path="/about-us" element={<PageAbout2 />} />

        <Route path="/dark/page-error404" element={<PageError404 />} />
        <Route
          path="/our-services/services-details/:slug"
          element={<PageServicesDetails />}
        />
        <Route path="/our-doctors" element={<PageTeam />} />
        <Route
          path="/our-doctor/doctor-details/:id"
          element={<PageTeamSingle />}
        />
        <Route path="/our-gallery" element={<PortfolioGallery />} />
      </Routes>
    </Router>
  );
}

export default App;
