import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Intro from "../../components/dark/about2/Intro";
import Numbers from "../../components/dark/about2/Numbers";
import About from "../../components/dark/about2/About";
import Portfolio from "../../components/dark/creative-agency/Portfolio";
import Testimonials from "../../components/dark/home-main/Testimonials";
import { useEffect } from "react";
import Header from "../../components/dark/about2/Header";
import { useDispatch, useSelector } from "react-redux";
import { fetchAboutUsData } from "../../app/features/aboutUsDataSlice";
import { fetchPolicyData } from "../../app/features/policyDataSlice";
import DynamicHeader from "../../components/dark/DynamicHeader";

export default function TermsCondition() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPolicyData());
  }, [dispatch]);
  const policies = useSelector((state) => state.policyData.policies);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms & Condition</title>
      </Helmet>
      <body>
        {/* <Intro title="About Us" /> */}
        <DynamicHeader
          title="Terms And Condition"
          beforePage="Home"
          currentPage="Terms And Condition"
        />

        <div className="container mt-50">
          <div dangerouslySetInnerHTML={{ __html: policies?.terms }}></div>
        </div>

        <Footer />
      </body>
    </>
  );
}
