import { Helmet } from "react-helmet";
import Footer from "../../components/dark/home-main/Footer";
import Header from "../../components/dark/team/Header";
import Team from "../../components/dark/team/Team";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMemberData } from "../../app/features/memberDataSlice";
export default function PageTeam() {
  const dispatch = useDispatch();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  const members = useSelector((state) => state.memberData.members);

  useEffect(() => {
    dispatch(fetchMemberData());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Our Doctors</title>
      </Helmet>
      <body>
        <Header />
        <Team members={members} />
        <Footer />
      </body>
    </>
  );
}
