import React, { useState } from "react";
import data from "../../../data/portfolios/works1.json";
import { Link } from "react-router-dom";

function Team({ members }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  console.log("members: ", members);

  return (
    <section className="team pb-60 pt-30">
      <div className="container">
        <div className="row">
          {members?.map((item, i) => (
            <div key={i} className="col-lg-4 col-md-6 mt-50">
              <div
                className=""
                style={{ borderRadius: "30px", overflow: "hidden" }}
              >
                <div className="profile-img-container">
                  <Link to={`/our-doctor/doctor-details/${item.id}`}>
                    <img
                      src={item.member_image}
                      alt="Profile"
                      className={`profile-img ${
                        hoveredIndex === i ? "" : "hidden"
                      }`}
                    />
                  </Link>
                  <Link to={`/our-doctor/doctor-details/${item.id}`}>
                    <img
                      src={item.member_image2}
                      alt="Profile Hover"
                      onMouseEnter={() => setHoveredIndex(i)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      className={`profile-img ${
                        hoveredIndex === i ? "hidden" : ""
                      }`}
                    />
                  </Link>
                </div>
                <div className="cont d-flex align-items-center mt-30 pb-15 bord-thin-bottom">
                  <div>
                    <h5>{item.member_name}</h5>
                    <p>{item.designation}</p>
                  </div>
                  <div className="ml-auto">
                    <Link
                      to={`/our-doctor/doctor-details/${item?.id}`}
                      className="rmore"
                    >
                      <img
                        src="/dark/assets/imgs/arrow-right.png"
                        alt=""
                        className="icon-img-20"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Team;
